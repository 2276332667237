import React, { Component } from "react"

class WeChatCallback extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get("code")

    let btn = window.parent.document.getElementById("WeChatCallback")
    btn.setAttribute("data-code", code)
    btn.click()
  }

  render() {
    return <React.Fragment></React.Fragment>
  }
}

export default WeChatCallback
